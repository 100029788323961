.loadingPageWrapper {
  height: 100vh;
  width: 100%;
  background-color: #37171c;
  color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.eventCover {
  height: 100vh;
  width: 100%;
  color: var(--white);
  background-position: center 40%;
  background-size: cover;
  /* background:linear-gradient(0deg, rgba(255, 0, 150, 0.3), rgba(255, 0, 150, 0.3)); */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
}

.firstSection {
  height: 100vh;
  width: 100%;
  color: var(--white);
  background-position: center 40%;
  background-size: cover;
  /* background:linear-gradient(0deg, rgba(255, 0, 150, 0.3), rgba(255, 0, 150, 0.3)); */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.firstSectionBg {
  background: linear-gradient(
      0deg,
      rgb(0, 0, 0) 20%,
      rgba(255, 255, 255, 0) 100%
    ),
    url(/src/assets/main_photo.jpg);
  background-position: center 40%;
  background-size: cover;
}

.firstSectionBgDesktop {
  background: linear-gradient(0deg, rgb(0, 0, 0), rgba(255, 255, 255, 0) 80%),
    url(/src/assets/main_landscape.jpg);
  background-position: center 40%;
  background-size: cover;
}

.coupleName {
  font-family: var(--cursiveFont);
  font-size: 48px;
  margin-bottom: 40px;
  /* font-weight: 700; */
}

.invDesc {
  padding: 40px;
  padding-bottom: 20px;
}

.button {
  background-color: #37171c;
  color: var(--white);
  /* width: max-content; */
  width: 190px;
  padding: 14px 0px;
  cursor: pointer;
  margin-bottom: 128px;
  margin-top: 12px;
  border-radius: 40px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 16px;
}

.button:hover {
  background-color: #634045;
}

.button img {
  margin-right: 12px;
  width: 16px;
  height: 16px;
}

.quoteWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: top;
  justify-content: center;

  height: 100vh;
  background: rgb(55, 23, 28);
  background: linear-gradient(
      0deg,
      rgba(55, 23, 28, 0.2) 0%,
      rgba(22, 11, 13, 0.7) 80%
    ),
    url(/src/assets/quoteCover.jpg);

  background-position: 52% 40%;
  background-size: cover;
  background-attachment: fixed;
  will-change: transform;
}

.quote {
  font-size: 24px;
  padding: 48px;
  padding-top: 25%;
  color: var(--white);
  /* font-family: var(--cursiveFont); */
}

.quote span {
  font-family: var(--cursiveFont);
  font-weight: 400;
}

.quote div {
  margin-top: 20px;
  font-size: 16px;
}

.sectionOneImg {
  padding: 48px;
  max-width: 300px;
  width: 90%;
  max-height: 85vh;
  border-radius: 12px;
}

.sliderStyle,
.sectionOneImg img {
  max-width: 280px;
  width: 90%;
  max-height: 85vh;
  border-radius: 12px;
}

.each-slide-effect > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 350px;
}

.groomBride {
  font-size: 32px;
  padding: min(15%, 180px) 10%;
  background-size: cover;
}

.event {
  background-color: #f7eae7 !important;
  padding: min(15%, 180px) 10%;
  /* display: flex;
  justify-content: space-around; */
  /* background-image: url(/src/assets/pastelBg.png); */

  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0) 95%,
      rgba(255, 255, 255, 1)
    ),
    url(/src/assets/pastelBg.png);
  background-size: cover;
  background-position: center;
}

.eventDetailWrapper {
  min-width: 60%;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 40px 20px;
  border-top-left-radius: 120px;
  border-bottom-right-radius: 120px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  margin-bottom: 64px;
}

.eventDetail {
  border-radius: 8px;
  color: #000000;
  max-width: 500px;
  font-size: 16px;
  margin: auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.liveStreamingWrapper {
  min-width: 60%;

  margin: auto;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
  margin-top: 48px;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.title {
  font-size: 36px;
  margin-bottom: 20px;
  font-family: var(--cursiveFont);
}

.titleDesktop {
  font-size: 64px;
  margin-bottom: 20px;
  font-family: var(--cursiveFont);
}

.rsvp {
  color: black;
  display: flex;
  align-items: center;
  padding-bottom: 72px;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 36px;
  text-align: left;
  justify-content: center;
}

.rsvpImg {
  max-height: 150px;
  max-width: 500px;
  width: 80%;
}

.name {
  font-size: 36px;
  margin-bottom: 16px;
  font-family: var(--cursiveFont);
}

.intro {
  font-size: 14px;
  font-family: var(--nonCursiveFont), sans-serif;
  margin-bottom: 48px;
  color: black !important;
}

.family {
  font-size: 14px;
  font-family: var(--nonCursiveFont), sans-serif;
}

.place {
  margin: 14px 0px;
}

.direction {
  margin-bottom: 50px;
}

.rsvpFormSection {
  background-color: #f7eae7;
  margin-top: 40px;
  padding: 20px;
  border-radius: 12px;
  flex: auto;
}

select {
  border-radius: 4px;
  padding: 6px;
}

select:disabled {
  color: black;
  background-color: none;
  opacity: 1;
}

option {
  padding: 6px;
  font-size: 18px;
}

option:checked {
  background: rgba(55, 23, 28, 0.3);
}

option:hover {
  background-color: black;
}

select:focus {
  border-color: #37171c;
  outline: none;
  box-shadow: 0 0 5px #37171c;
}

.attRow {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
}

.attRow div:first-child {
  flex: auto;
}

.attRow div:last-child {
  flex: auto;
}

.submitBtn {
  background-color: #37171c;
  color: var(--white);
  flex: auto;
  padding: 10px;
  cursor: pointer;
  border-radius: 8px;
  text-align: center;
  margin-top: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.submitBtn:hover {
  background-color: #634045;
}

/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border-radius: 50%;
  border: solid 1px #37171c;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #ffffff;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
  top: 6px;
  left: 6px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #37171c;
}

.loadingOverlay {
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8); /* Black background with opacity */
  z-index: 1000; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

.loadingContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 16px;
  color: #37171c;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: white;
  padding: 30px;
  border-radius: 12px;
  width: 200px;
}

.errMsg {
  color: #b94254;
  font-weight: bold;
}

.playPauseButton {
  font-size: 30px;
  cursor: pointer;
  margin: 0;
  line-height: none !important;
}

.vinyl {
  position: fixed;
  bottom: 20px;
  left: 10px;
  z-index: 999;
  width: 60px;
  height: 60px;
  border-radius: 25px;
  padding: 10px;
}

.play {
  -webkit-animation: rotation 5s infinite linear;
  -moz-animation: rotation 5s infinite linear;
  -ms-animation: rotation 5s infinite linear;
  -o-animation: rotation 5s infinite linear;
  animation: rotation 5s infinite linear;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.countdownContainer {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 50%,
      rgba(255, 255, 255, 1)
    ),
    url(/src/assets/saveTheDate.jpg);
  /* background-image: url(/src/assets/saveTheDate.jpg); */
  background-size: cover;
  background-position: top;
  height: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
}

.timerWrapper {
  display: flex;
}

/** MOBILE **/
.countdownBox {
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  border-radius: 10px;
  width: 40px;
  padding: 10px;
  font-size: 10px;
  display: flex;
  flex-direction: column;
}

.countdownBox:nth-child(2) {
  margin: 0px 15px;
}

.countdownBox:nth-child(3) {
  margin-left: 0px;
  margin-right: 15px;
}

.countdownNumber {
  font-size: 24px;
}

/** DESKTOP **/
.countdownBox-desktop {
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  border-radius: 10px;
  width: 80px;
  padding: 20px;
  font-size: 18px;
  display: flex;
  flex-direction: column;
}

.countdownBox-desktop:nth-child(2) {
  margin: 0px 45px;
}

.countdownBox-desktop:nth-child(3) {
  margin-left: 0px;
  margin-right: 45px;
}

.countdownNumber-desktop {
  font-size: 64px;
}

.protocolContainer {
  padding: min(15%, 180px) 10%;
}

.protocolItemWrapper {
  display: flex;
  justify-content: space-around;
  max-width: 1200px;
  margin: auto;
  margin-top: 48px;
}

.protocolItemWrapper img {
  width: 64px;
  height: 64px;
  margin-right: 16px;
}

.protocolItem {
  display: flex;
  align-items: center;
  text-align: left;
}

.protocolItem:first-child {
  margin-bottom: 32px;
}
